/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import ModuleWrapper from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import ArrowButton from '@components/UI/arrowButton';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { useTeamQuery } from '@querys/useTeamQuery';
import { useSolutionQuery } from '@querys/useSolutionQuery';
import { useLocationsQuery } from '@querys/useLocationsQuery';
import cx from 'classnames';
import { useSiteContext, useFilters } from '@lib/context';
import { useEffect } from 'react';

const variants = {
  initial: {
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  active: {
    scale: 1.1,
    transition: {
      duration: 1,
    },
  },
};

const FilteredTeamGrid = ({ config, title, subtitle, contactPage }) => {
  const { state } = useSiteContext();
  const { updateLocation, updateSolution, nukeFilters } = useFilters();
  const { nodes } = useTeamQuery();
  const { solutions } = useSolutionQuery();
  const { locations } = useLocationsQuery();

  //nuke filters if on contact page
  useEffect(() => {
    if (contactPage) {
      nukeFilters();
    }
  }, []);

  // filter only finance advisors
  const financeAdvisors = nodes?.filter((node) => node?.solutionRef.length > 0);

  const locationFilter = (item) => {
    const filtered = item?.locationRef?.filter(
      (loc) => loc?.name === state?.location
    );
    return filtered?.[0]?.name;
  };

  // return boolean if current solution state matches solution ref
  const solutionFilter = (item) => {
    const filtered = item?.solutionRef?.filter(
      (sol) => sol?.slug?.current === state?.solution
    );
    return filtered[0]?.slug?.current === state?.solution;
  };

  // create new array with shuffled financeAdvisors using useEffect
  const [shuffledAdvisors, setShuffledAdvisors] = useState([]);
  useEffect(() => {
    const shuffled = financeAdvisors?.sort(() => 0.5 - Math.random());
    setShuffledAdvisors(shuffled);
  }, []);

  const filteredTeam = shuffledAdvisors?.filter((item) => {
    if (state?.location !== 'default' && state?.solution !== 'default') {
      return locationFilter(item) && solutionFilter(item);
    } else if (state?.location !== 'default') {
      return locationFilter(item);
    } else if (state?.solution !== 'default') {
      return solutionFilter(item);
    } else {
      return item;
    }
  });

  const [teamAmount, setTeamAmount] = useState(6);
  const filteredAmount = filteredTeam?.slice(0, teamAmount);

  const handleMore = () => {
    setTeamAmount((teamAmount) => teamAmount + 3);
  };

  const [hoverIndex, setHoverIndex] = useState(null);

  // create function to accept an array of locations and return a string of locations with and & between the last two, or single location
  const locationString = (locations) => {
    if (locations?.length === 1) {
      return locations[0]?.name;
    } else if (locations?.length === 2) {
      return `${locations[0]?.name} & ${locations[1]?.name}`;
    } else {
      const newlocations = locations?.map((loc) => loc?.name);
      const lastLocation = newlocations?.pop();
      const locationString = newlocations?.join(', ');
      return `${locationString} & ${lastLocation}`;
    }
  };

  return (
    <ModuleWrapper {...config} className='px-gutter'>
      <div
        className={cx(' max-w-[877px]', {
          title: 'pb-space-s',
        })}
      >
        {title && <h2 className='richH1 mb-[30px] '>{title}</h2>}
        {subtitle && <h3 className='richH2 text-grey '>{subtitle}</h3>}
      </div>

      <div className='mb-space-s flex flex-col md:flex-row md:space-x-16'>
        <select
          name='location'
          id=''
          className={cx('filterInput ', {})}
          onChange={(e) => updateLocation(e.target.value)}
        >
          <option disabled selected hidden>
            Location
          </option>

          <option value='default'>All Locations</option>
          {locations?.map((location) => (
            <option
              value={location?.name}
              selected={location?.name === state?.location}
            >
              {location?.name}
            </option>
          ))}
        </select>
        <select
          name='solution'
          id=''
          className='filterInput mt-10 md:m-0'
          onChange={(e) => {
            updateSolution(e.target.value);
          }}
        >
          <option disabled selected hidden>
            Finance Solution
          </option>
          <option value='default'>All Finance Solutions</option>
          {solutions?.map((solution) => (
            <option
              value={solution?.slug?.current}
              selected={solution?.slug?.current === state?.solution}
            >
              {solution?.title}
            </option>
          ))}
        </select>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-11 gap-y-[75px]'>
        {filteredAmount?.map((member, i) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <Link
              to={`/team/${member?.slug?.current}`}
              key={i}
              onMouseEnter={() => setHoverIndex(i)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <div className='blockH3'>
                {' '}
                <ArrowButton linkText={member?.fullName} />
              </div>

              <div className='mt-[34px] aspect-w-1 aspect-h-1 relative overflow-hidden rounded-[20px]'>
                <motion.div
                  variants={variants}
                  initial='initial'
                  animate={hoverIndex === i ? 'active' : 'initial'}
                >
                  {member?.bioImage && (
                    <SanityImage
                      image={member?.bioImage}
                      className='w-full h-full absolute inset-0 object-cover'
                    />
                  )}
                </motion.div>
              </div>
              <div className='mt-5 xl:mt-[41px]'>
                <h2 className='blockH4'>{member?.role}</h2>
                <h3 className='blockH5 text-grey pt-2 xl:pt-4'>
                  {' '}
                  {locationString(member?.locationRef)}
                </h3>
              </div>
            </Link>
          </motion.div>
        ))}
        {filteredAmount?.length === 0 && (
          <div className='flex flex-col items-left justify-center col-span-full'>
            <h2 className='blockH4 '>
              No results were found. Please try again using different filters.
            </h2>
          </div>
        )}
      </div>
      {teamAmount <= filteredTeam.length && (
        <div onClick={handleMore} className='blockH5 pt-space-s w-fit mx-auto'>
          <ArrowButton linkText='See more' />
        </div>
      )}
    </ModuleWrapper>
  );
};

export default FilteredTeamGrid;
