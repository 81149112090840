/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import IntroBlock from '@components/other/introBlock';
import { useLocationsQuery } from '@querys/useLocationsQuery';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import FilteredTeamGrid from '@components/pageModules/modules/filteredTeamGrid';
import { Link as ScrollLink } from 'react-scroll';
import { useFilters } from '@lib/context';

import ContactForm from '@components/forms/contactForm';
import { Map } from '../components/pageModules/modules/ctaBlock';

const Contact = ({ preview = false, data, previewDoc }) => {
  const { updateLocation } = useFilters();
  const page = preview ? previewDoc : data?.page;
  const { locationsHeader, seo, title, subtitle, contactUs } = page || {};
  const { locations } = useLocationsQuery();

  const breakpoints = useBreakpoint();

  const [, setPopup] = useState(false);

  const lat = -40.71096197479495;
  const long = 172.50638083137534;

  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: long,
    zoom: breakpoints.lg ? 4.6 : 4,
  });

  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />
      <div className='sm:mt-space-m px-gutter'>
        {/* <h2 className="blockH1">{contactUs?.header}</h2> */}
        <ContactForm contactUs={contactUs} />
      </div>
      <div className='bg-white border-[3px] border-yellow  mx-gutter lg:pl-[76px] mt-space-m rounded-[50px] relative z-10 overflow-hidden grid grid-cols-1 lg:grid-cols-2'>
        <div className='flex flex-col py-[56px] px-gutter lg:px-0'>
          <h2 className='blockH1 mb-10 lg:mb-24'>{locationsHeader}</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-28'>
            {locations?.map((location, i) => (
              <ScrollLink
                key={i}
                onClick={() => {
                  updateLocation(location?.name);
                }}
                className='blockH6 cursor-pointer hover:text-yellow transition-colors duration-300'
                to='contactTeam'
                offset={100}
                smooth={true}
              >
                {location?.name}
              </ScrollLink>
            ))}
          </div>
        </div>

        <div className='h-[450px] lg:h-full'>
          <Map
            style='mapbox://styles/ryanfair/claaaum8z000f14qde4v4umdx'
            viewport={viewport}
            setViewport={setViewport}
            popup={false}
            setPopup={setPopup}
            contact={true}
          />
        </div>
      </div>
      <div className='pt-space-m' id='contactTeam'>
        <FilteredTeamGrid
          contactPage={true}
          title='Business Finance Advisers'
          subtitle='Our team provides a personalised service throughout New Zealand. Getting to know you, your business and your goals in depth is what sets us apart.​'
        />
      </div>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query sanityContactPageQuery {
    page: sanityContactPage {
      title
      subtitle
      locationsHeader
      locationImage {
        ...ImageWithPreview
      }
      contactUs {
        header
        body: _rawBody(resolveReferences: { maxDepth: 10 })
      }
      seo {
        ...SEO
      }
    }
  }
`;
