import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StandardInput } from '@components/UI/inputs';
import cx from 'classnames';
import SimplePortableText from '@components/sanity/simplePortableText';
import { motion } from 'framer-motion';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

// Form Boilerplate
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  find: '',
};

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name Required'),
  lastName: Yup.string().required('Last Name Required'),
  email: Yup.string().email('Invalid Email').required('Email Is Required'),
  message: Yup.string().required('Message Required'),
  phone: Yup.string().required('Phone Required'),
  find: Yup.string().required('Phone Required'),
});

const ContactForm = ({ contactUs }) => {
  const { pathname } = useLocation();

  const [submitted, setSubmitted] = useState(false);

  return (
    <div className='md:grid grid-cols-14 my-10 mx-auto  w-full h-full   py-8  '>
      <div className='col-start-1 col-end-5 mb-20 md:mb-0'>
        <div className=''>
          <SimplePortableText text={contactUs?.body} />
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          // Submit to netlify
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', ...values }),
          })
            .then(async () => {
              const response = await fetch(
                '/.netlify/functions/handle-contact-submission',
                {
                  method: 'POST',
                  body: JSON.stringify(values),
                  headers: { 'Content-Type': 'application/json' },
                }
              );
            })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .then(() => setSubmitted(true))
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          /* and other goodies */
        }) => (
          <Form
            name='contact'
            data-netlify='true'
            className={cx(
              'text-grey flex flex-col gap-4 lg:grid grid-cols-12 lg:gap-8  tracking-[.1em] text-dark-green col-start-7 col-end-15',
              {
                'h-full': submitted,
              }
            )}
          >
            <input type='hidden' name='form-name' value='contact' />

            {status === 'success' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className='text-center max-w-[650px] mx-auto mb-[50px] col-span-full self-center'
              >
                <h2 className='blockH4 '>
                  Thank you, we will be in touch shortly!
                </h2>
              </motion.div>
            ) : (
              <React.Fragment>
                <div className='col-start-1 col-end-7'>
                  <StandardInput name='firstName' placeholder='First Name' />
                </div>
                <div className='col-start-7 col-end-13'>
                  <StandardInput name='lastName' placeholder='Last Name' />
                </div>

                <div className='col-start-1 col-end-7'>
                  <StandardInput name='email' placeholder='Email Address' />
                </div>
                <div className='col-start-7 col-end-13 '>
                  <StandardInput name='phone' placeholder='Phone Number' />
                </div>
                <div className='col-span-full '>
                  <StandardInput
                    name='find'
                    placeholder='How did you find us?'
                  />
                </div>

                <div className='col-span-full '>
                  <StandardInput
                    name='message'
                    placeholder='Your Message'
                    as='textarea'
                    rows='6'
                  />
                </div>

                <button
                  className='button-yellow justify-self-end w-fit  col-span-full mt-10 lg:mt-0  '
                  type='submit'
                >
                  Submit
                </button>
              </React.Fragment>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
